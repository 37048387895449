import React from 'react';
import type { PlanT } from '@mentimeter/plans-content';
import { Box, Text, Badge } from '@mentimeter/ragnar-ui';
import { greenLight500 } from '@mentimeter/ragnar-colors';
import { handlePlanCalculationAndFormatting } from '../../../utils/planCalculations';

export function DiscountedPrice({
  plan,
  selectedValue,
  discountPrice,
  showLicenseSelector,
}: {
  plan: PlanT;
  selectedValue: number;
  discountPrice: string;
  showLicenseSelector: boolean;
}) {
  return (
    <Box flexDirection="row" alignItems="center">
      <Text
        color="textWeaker"
        lineHeight="100"
        extend={() => ({ textDecoration: 'line-through' })}
      >
        {showLicenseSelector
          ? handlePlanCalculationAndFormatting(
              plan.discount!.tiers,
              plan.discount!.baseAmount,
              selectedValue,
              plan.priceFormat,
              plan.currencySymbol,
              discountPrice,
              plan.isDisabled,
            )
          : discountPrice}
      </Text>
      <Box flexDirection="row" alignItems="center">
        <Badge
          bg={greenLight500}
          px="space2"
          ml="space2"
          type="text"
          color="onPositiveWeakest"
          text={plan.discount!.save}
          compact
        />
      </Box>
    </Box>
  );
}
