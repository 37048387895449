import { Trans, useTranslation } from '@mentimeter/i18n';
import { Box, Text } from '@mentimeter/ragnar-ui';

interface BankTransferProps {
  email: string;
}

export function BankTransferPaymentMethod({ email }: BankTransferProps) {
  const { t } = useTranslation('checkoutUiPackage');

  return (
    <Box width="100%">
      <Text variant="dashboardBodySm">
        <Trans
          t={t}
          i18nKey="checkout_modal.payment_method.bank_transfer"
          values={{ email }}
          components={{
            bold: <Text variant="dashboardBodySm" fontWeight="semiBold" />,
          }}
        />
      </Text>
    </Box>
  );
}
