import React, { useState } from 'react';
import { UserIcon } from '@mentimeter/ragnar-visuals';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { gray1300 } from '@mentimeter/ragnar-colors';
import { Image } from '../Image';
import { Text } from '../Text';
import { Box } from '../Box';
import { variants, textVariants } from './Avatar.variants';
import { getAvatarColor } from './Avatar.utils';
import { type AvatarT } from './Avatar.types';

export const AvatarCircle = ({
  itemId,
  name,
  initials = '',
  badge,
  profilePictureUrl,
  size = 'default',
  fontSize,
  'aria-label': ariaLabel,
  disabled = false,
  className,
  style,
  ...rest
}: AvatarT) => {
  const [profilePictureFailedToLoad, setProfilePictureFailedToLoad] =
    useState(false);

  const defaultClasses = [
    'flex',
    'items-center',
    'justify-center',
    'rounded-full',
    'overflow-hidden',
  ];

  const variantClasses = variants({ size });
  const textVariantClasses = textVariants({ size });
  const bgClass = disabled ? 'bg-disabled-strong' : getAvatarColor(itemId);

  return (
    <Box
      aria-label={ariaLabel}
      className={clsx(defaultClasses, variantClasses, bgClass, className)}
      {...rest}
    >
      {profilePictureUrl && !profilePictureFailedToLoad ? (
        <Image
          src={profilePictureUrl}
          alt={name ? `Profile picture for ${name}` : 'Profile picture'}
          onError={() => {
            setProfilePictureFailedToLoad(true);
          }}
          className={clsx('w-full', disabled && 'opacity-40')}
        />
      ) : initials !== '' ? (
        <Text
          className={clsx(
            textVariantClasses,
            disabled ? 'text-on-disabled-strong' : 'text-black-base',
          )}
          {...(fontSize && { fontSize })}
        >
          {initials}
        </Text>
      ) : (
        <UserIcon size={1} color={disabled ? 'onDisabledStrong' : gray1300} />
      )}
    </Box>
  );
};
