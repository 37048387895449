import { Trans, useTranslation } from '@mentimeter/i18n';
import { Box, Text } from '@mentimeter/ragnar-ui';

interface NoPaymentMethodProps {
  email: string;
}

export function NoPaymentMethod({ email }: NoPaymentMethodProps) {
  const { t } = useTranslation('checkoutUiPackage');

  return (
    <Box width="100%">
      <Text fontSize="87.5">
        <Trans
          t={t}
          i18nKey="checkout_modal.payment_method.no_payment_method"
          values={{ email }}
          components={{ bold: <Text fontSize="87.5" fontWeight="semiBold" /> }}
        />
      </Text>
    </Box>
  );
}
