import React from 'react';
import type { PlanT } from '@mentimeter/plans-content';
import { Box, Text, TooltipDescriptive } from '@mentimeter/ragnar-ui';
import { Selector } from '@mentimeter/checkout-ui';
import type { TFunction } from '@mentimeter/i18n';
import { trackUser } from '@api/tracking/client';

export function LicenseSelector({
  selectedValue,
  setSelectedValue,
  licenseValues,
  plan,
  t,
}: {
  selectedValue: number;
  setSelectedValue: (value: number) => void;
  licenseValues: string[];
  plan: PlanT;
  t: TFunction;
}) {
  return (
    <>
      <Box flexDirection="row" alignContent="center" mb="space2">
        <Text fontSize="87.5" fontWeight="semiBold" color="text">
          {t('plans.licenses_picker.licenses')}
        </Text>
        <TooltipDescriptive
          id={`${plan.id}-licenses-question-mark`}
          description={t('plans.licenses_picker.tooltip_info')}
        />
      </Box>
      <Selector
        aria-label={t('plans.licenses_picker.licenses_aria_label')}
        disabled={plan?.isDisabled ?? false}
        itemStructure={[{ values: licenseValues }]}
        selectedValue={plan?.isDisabled ? '1' : selectedValue.toString()}
        onOpenChange={(isOpen) => {
          if (isOpen) {
            trackUser({
              event: 'Clicked license selector',
              properties: { context: 'Plans page', isOpen },
            });
          }
        }}
        onValueChange={(licenses) => {
          setSelectedValue(parseInt(licenses));
          trackUser({
            event: 'Selected licenses',
            properties: {
              context: 'Plans page',
              plan: plan.id,
              licenses: parseInt(licenses),
            },
          });
        }}
      />
    </>
  );
}
