import type { UserResponseT, UserFlagsT } from '@mentimeter/http-clients';
import type { AxiosError } from 'axios';

interface FlattenedFlags {
  receiveResultsEmail: boolean;
  aiFeaturesEnabled: boolean;
  onboarding?: string;
  optOutCsat: boolean;
}

export enum UserLevelEnum {
  FREE = 'free',
  BASIC = 'basic',
  PRO = 'pro',
  PRO_MULTI = 'pro-multi',
  ENTERPRISE_HIGH = 'enterprise-high',
  ENTERPRISE_TRIAL = 'enterprise-trial',
  ENTERPRISE = 'enterprise',
}

export type UserT = FlattenedFlags & {
  region: UserRequestT['region'];
  createdAt: string;
  id: number;
  name: string;
  address: string;
  email: string;
  initials: string;
  externalIdentities?: ExternalIdentityT[];
  loginMethod: 'sso' | 'social' | 'password';
  hasPassword: boolean;
  hasSso: boolean;
  isFreeUser: boolean;
  features: UserResponseT['features'];
  role?: UserResponseT['role'];
  ownerOrAdmin: boolean;
  payments_provider: 'stripe' | 'unknown';
  workspaceId?: number;
  betaStatus: 'active' | 'standard' | 'inactive';
  intercomHash?: string;
  flags: UserFlagsT;
  collaboration_rights: UserResponseT['collaboration_rights'];
  profile_picture: UserResponseT['profile_picture'];
  service_urls: UserResponseT['service_urls'];
  isVerified: boolean;
  requiresEmailVerification: boolean;
  token: UserResponseT['token'];
};

export type ExternalIdentityT = 'google' | 'facebook';

export type UserRequestT = Partial<
  UserResponseT & {
    flags: Partial<UserResponseT['flags']>;
    current_password: string;
    password: string;
  }
>;

export type UserUpdateT = Partial<
  UserT & {
    currentPassword: string;
    password: string;
  }
>;

interface Common {
  error: AxiosError;

  /** update the user data on the API */
  update: (
    data: UserUpdateT,
    context: string,
    options?: {
      /** If set to false, wait for the server response to update state. Defaults to true. */
      shouldUpdateOptimistically: boolean;
    },
  ) => Promise<void>;

  /** logout the user and redirect them to the path given as an argument */
  logout: (redirectPath: string) => Promise<void>;
}

export interface Stale extends Common {
  isAuthenticated: true;
  isLoading: boolean;
  staleCache: true;
  user: UserT;
}
export interface Authenticated extends Common {
  /** whether the user is currently logged in*/
  isAuthenticated: true;
  isLoading: boolean;

  /** whether data is taken from localStorage, or freshly fetched from the API */
  staleCache: boolean;

  /** the currently logged in user */
  user: UserT;
}

interface Unauthenticated {
  isAuthenticated: false;
  isLoading: boolean;
  staleCache: false;
  user: null;
  error?: AxiosError;
  update: undefined;
}

interface Server {
  data: undefined;
  error?: undefined;
  staleCache: false;
  isAuthenticated: false;
  isLoading: boolean;
  user: null;
  update: undefined;
}

export type UseUserT = Authenticated | Unauthenticated | Stale | Server;

interface UserFromCache {
  fromCache?: boolean;
}
export type UserFromCacheOrApi = UserResponseT & UserFromCache;
