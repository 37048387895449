import { Button, Text } from '@mentimeter/ragnar-ui';
import { useTranslation } from '@mentimeter/i18n';
import { ErrorMessageWrapper } from './components/ErrorMessageWrapper';
import { ErrorMessageTitle } from './components/ErrorMessageTitle';

export const UpgradePermissionDenied = () => {
  const { t } = useTranslation('checkoutUiPackage');

  return (
    <ErrorMessageWrapper>
      <ErrorMessageTitle text={t('errors.upgrade_denied')} />
      <Text textAlign="center" mt="space4">
        {t('errors.upgrade_denied_info')}
      </Text>
      <Button variant="primary" href="/app/manage-members" mt="space4">
        {t('errors.find_owner')}
      </Button>
    </ErrorMessageWrapper>
  );
};
