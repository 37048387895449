import React from 'react';
import type { PlanT } from '@mentimeter/plans-content';
import { Box, Button } from '@mentimeter/ragnar-ui';
import type { HandlePlanClickT } from '@mentimeter/checkout-modal';
import type { TFunction } from '@mentimeter/i18n';
import { trackUser } from '@api/tracking/client';
import { BuyPlanButton } from '../../../components/BuyPlanButton';
import { LICENSE_CAP } from '../constants';

export function ActionButton({
  plan,
  selectedValue,
  setModalState,
  onPlanClick,
  t,
}: {
  plan: PlanT;
  selectedValue: number;
  setModalState: (value: boolean) => void;
  onPlanClick: HandlePlanClickT | undefined;
  t: TFunction;
}) {
  return (
    <React.Fragment>
      <BuyPlanButton
        highlight={plan.highlight}
        disabled={plan.action.disabled}
        onClick={() => {
          if (selectedValue === LICENSE_CAP) {
            setModalState(true);
          } else {
            onPlanClick?.({
              plan: plan.category,
              isCurrentSubscription: !!plan.isCurrentSubscription,
              isChangingBillingCycle: !!plan.isChangingBillingCycle,
              href: plan.showLicenseSelector
                ? plan.action.href + `?licenses=${selectedValue}`
                : plan.action.href,
            });
          }
          if (plan.track) plan.track();
        }}
      >
        {getButtonText(plan, selectedValue, t)}
      </BuyPlanButton>
      {plan.showQuoteButton && selectedValue > 1 && (
        <Box width="100%" alignItems="center" mt="space4">
          <Button
            variant="secondary"
            target="_blank"
            href={`/quote?licenses=${selectedValue}&plan=${plan.id}`}
            onClick={() => {
              trackUser({
                event: 'Clicked download quote',
                properties: { context: 'Plans page' },
              });
            }}
          >
            {t('plans.download_quote')}
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
}

// This is part of the PLS dropdown experiment, if we choose to implement this feature we should remove this
// function that's overriding action children and change the way we set the button text initially / all together
function getButtonText(plan: PlanT, selectedValue: number, t: TFunction) {
  const initialBtnText = plan.action.children;

  if (plan.showLicenseSelector && initialBtnText !== t('plans.only_yearly')) {
    const singleLicense = t('plans.licenses_picker.buy_single', {
      plan: plan.heading,
    });
    const multipleLicenses = t('plans.licenses_picker.buy_multiple', {
      licenses: selectedValue,
      plan: plan.heading,
    });
    return selectedValue > 1 ? multipleLicenses : singleLicense;
  }

  return initialBtnText;
}
