import type { SubscriptionsResponseT } from '@mentimeter/http-clients';
import {
  SubscriptionStatus,
  StripeSubscriptionStatus,
  PlanCategory,
} from '@mentimeter/http-clients';

export function billingRules(
  subscriptions: SubscriptionsResponseT | undefined,
) {
  const currentSubscription = subscriptions?.current_subscription;
  const planKey = currentSubscription?.plan.key;
  const planCategory = currentSubscription?.plan.category;
  const planRecurring = currentSubscription?.plan.recurring;
  const planFeatures = currentSubscription?.plan.features;
  const planName = currentSubscription?.plan.name;
  const licenseAmount = currentSubscription?.licenses;

  const isConference =
    planCategory === PlanCategory.CONFERENCE_SMALL ||
    planCategory === PlanCategory.CONFERENCE_LARGE;
  const hasCurrentSubscription = Boolean(subscriptions?.current_subscription);
  const isActive = currentSubscription?.status === SubscriptionStatus.ACTIVE;
  const isRenewalCanceled = currentSubscription?.cancel_renewal === true;
  const isCanceled =
    currentSubscription?.status === SubscriptionStatus.CANCELED;
  const isPending = currentSubscription?.status === SubscriptionStatus.PENDING;
  const isPastDue = currentSubscription?.status === SubscriptionStatus.PAST_DUE;
  const isExpired = currentSubscription?.status === SubscriptionStatus.EXPIRED;
  const isInvoice = planKey === PlanCategory.INVOICE;
  const isMonthly =
    planCategory === PlanCategory.BASIC_MONTHLY ||
    planCategory === PlanCategory.EDUCATIONAL_BASIC_MONTHLY;
  const isEducational =
    planCategory === PlanCategory.EDUCATIONAL_BASIC ||
    planCategory === PlanCategory.EDUCATIONAL_BASIC_MONTHLY ||
    planCategory === PlanCategory.EDUCATIONAL_PRO;
  const isRecurring = planRecurring !== 'none';
  const isTrialing =
    currentSubscription?.provider_status === StripeSubscriptionStatus.TRIALING;

  return {
    isEducational,
    isMonthly,
    isActive,
    isRenewalCanceled,
    isCanceled,
    isPending,
    isPastDue,
    isExpired,
    isInvoice,
    hasCurrentSubscription,
    isConference,
    isRecurring,
    isTrialing,
    planFeatures,
    planName,
    planKey,
    planCategory,
    licenseAmount,
  };
}
