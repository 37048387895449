import { getFormattedPrice, type TierT } from '@mentimeter/billing';
import type { PlanT } from '@mentimeter/plans-content';

export function calculatePrice(
  tiers: TierT[] | null,
  selectedValue: number,
  amount: PlanT['amount'],
) {
  // Find the correct tier based on the selectedValue
  const tier = tiers?.find((t) => t.upTo >= selectedValue);
  const planPrice = tier
    ? tier.unitAmount * selectedValue
    : amount && amount * selectedValue;
  return planPrice;
}

export const handlePlanCalculationAndFormatting = (
  tiers: TierT[] | null,
  amount: PlanT['amount'],
  selectedValue: number,
  priceFormat: PlanT['priceFormat'],
  currencySymbol: PlanT['currencySymbol'],
  defaultPrice: string,
  disabled?: boolean,
) => {
  if (disabled && currencySymbol && priceFormat)
    return getFormattedPrice(amount, currencySymbol, priceFormat);

  const planCalculation = calculatePrice(tiers, selectedValue, amount);
  // TODO: If we decide to continue using the selector after the experiment, we should make 'priceFormat' and 'currencySymbol' required fields and remove the 'price' field  in the 'plan' object.
  // This would allow us to handle currency formatting within this component to reducing the need for workarounds and be able to calculate and work with the numbers since the 'price' field is only for displaying the amount correct format for the user
  if (currencySymbol && priceFormat && planCalculation)
    return getFormattedPrice(planCalculation, currencySymbol, priceFormat);

  return defaultPrice;
};
