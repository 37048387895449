import React from 'react';
import type { PlanT, MetaT } from '@mentimeter/plans-content';
import { Box, Text, Badge } from '@mentimeter/ragnar-ui';
import { greenLight500 } from '@mentimeter/ragnar-colors';

export function AdditionalPriceInfo({
  plan,
  showYearlyDiscount,
}: {
  plan: PlanT;
  showYearlyDiscount: (item: MetaT) => boolean;
}) {
  return (
    <Box>
      {plan.newPriceMeta?.map((item) => (
        <Box key={item.text} flexDirection="row" alignItems="center">
          <Text key={item.text} color="textWeaker" fontSize="87.5">
            {item.text}
            {showYearlyDiscount(item) && (
              <Box display="inline-block">
                <Badge
                  bg={greenLight500}
                  px="space2"
                  mt={plan.showLicenseSelector ? 'space2' : 'space0'}
                  ml={plan.showLicenseSelector ? 'space0' : 'space2'}
                  type="text"
                  color="onPositiveWeakest"
                  text={item.yearlyDiscountText}
                  compact
                />
              </Box>
            )}
          </Text>
        </Box>
      ))}
    </Box>
  );
}
