import {
  Badge,
  Box,
  Button,
  Link,
  Skeleton,
  Text,
  TooltipGeneric,
} from '@mentimeter/ragnar-ui';
import React from 'react';
import { LockIcon, QuestionMarkCircleIcon } from '@mentimeter/ragnar-visuals';
import { Trans, useTranslation } from '@mentimeter/i18n';
import { PaymentMethodType } from '@mentimeter/http-clients';
import type { PlanData } from './types';

interface PurchaseSummaryProps {
  isLoading: boolean;
  newPlan: PlanData | undefined;
  currentPlan: PlanData | undefined;
  hasTax: boolean | undefined;
  taxPercentage: string | undefined;
  tax: string | undefined;
  total: string | undefined;
  progressiveDiscountAmount: string | undefined;
  progressiveDiscountRate: number | undefined;
  purchaseLoading: boolean;
  purchaseError: string;
  paymentMethod?: PaymentMethodType;
  onSubmit: () => void;
}

export const PurchaseSummary = ({
  newPlan,
  currentPlan,
  hasTax,
  taxPercentage,
  tax,
  total,
  progressiveDiscountAmount,
  progressiveDiscountRate,
  onSubmit,
  purchaseLoading,
  purchaseError,
  isLoading,
  paymentMethod,
}: PurchaseSummaryProps) => {
  const { t } = useTranslation('checkoutUiPackage');
  const loading = isLoading || !currentPlan || !newPlan;
  const isBankTransferPaymentMethod =
    paymentMethod === PaymentMethodType.BankAccount ||
    paymentMethod === PaymentMethodType.VirtualAccount;

  return (
    <>
      <Box flexDirection="row" width="100%">
        <Box>
          {loading ? (
            <Box width={200}>
              <Skeleton variant="text" fontSize="100" width="60%" />
              <Skeleton variant="text" fontSize="87.5" width="70%" />
            </Box>
          ) : (
            <>
              <Text fontSize="87.5" color="text">
                {newPlan.unitPrice} x{' '}
                {t('licenses', { count: newPlan.licenses })}
              </Text>
              <Text fontSize="87.5" color="textWeaker">
                {t('checkout_modal.billed', {
                  billingCycle: t(newPlan.cycle),
                })}
              </Text>
            </>
          )}
        </Box>
        <Box flex="1 1 auto" ml="space1" alignItems="end">
          {loading ? (
            <Skeleton variant="text" width="100%" />
          ) : (
            <Text fontSize="87.5" color="text" textAlign="end">
              {newPlan.price}
            </Text>
          )}
        </Box>
      </Box>
      {progressiveDiscountAmount && progressiveDiscountRate && (
        <Box flexDirection="row" mt="space4" width="100%">
          <Box>
            <Badge
              type="text"
              color="onPositiveWeak"
              bg="positiveWeak"
              compact
              text={`${progressiveDiscountRate * 100}% ${t('discount')}`}
            />
            <Text fontSize="87.5" color="text">
              {t('checkout_modal.success_screens.bulk_purchase_title')}
            </Text>
          </Box>
          <Box flex="1 1 auto" ml="space1" alignItems="end">
            <Text fontSize="87.5" color="text">
              -{progressiveDiscountAmount}
            </Text>
          </Box>
        </Box>
      )}
      <Box flexDirection="row" mt="space3" width="100%">
        <Box>
          {loading ? (
            <Skeleton variant="text" width={200} />
          ) : (
            <>
              <Box flexDirection="row" alignItems="center">
                <Text fontSize="87.5" color="text">
                  {t('checkout_modal.success_screens.prorated_amount')}
                </Text>
                <Box
                  id="tooltiptarget"
                  ml="space1"
                  style={{
                    cursor: 'help',
                    lineHeight: 'normal',
                  }}
                >
                  <QuestionMarkCircleIcon color="textWeak" size={1} />
                </Box>
              </Box>

              <TooltipGeneric referenceId="tooltiptarget" placement="bottom">
                <Box
                  py="space2"
                  px="space4"
                  width="200px"
                  borderRadius={1}
                  bg="text"
                >
                  <Text fontSize="87.5" color="bg">
                    {t('checkout_modal.success_screens.info_tooltip', {
                      days: currentPlan?.daysUntilSubscriptionEnds || '',
                    })}
                  </Text>
                </Box>
              </TooltipGeneric>
            </>
          )}
        </Box>
        <Box flex="1 1 auto" ml="space1" alignItems="end">
          {loading ? (
            <Skeleton variant="text" />
          ) : (
            <Text fontSize="87.5" color="text" textAlign="end">
              {newPlan.proratedDiscount}
            </Text>
          )}
        </Box>
      </Box>
      {(hasTax || loading) && (
        <Box flexDirection="row" mt="space3" width="100%">
          <Box>
            {loading ? (
              <Skeleton variant="text" width={150} />
            ) : (
              <Text fontSize="87.5" color="text">
                {t('tax')} ({taxPercentage})
              </Text>
            )}
          </Box>
          <Box flex="1 1 auto" alignItems="end">
            {loading ? (
              <Skeleton variant="text" />
            ) : (
              <Text fontSize="87.5" color="text" textAlign="end">
                {tax}
              </Text>
            )}
          </Box>
        </Box>
      )}
      <Box
        mt="space6"
        borderTopWidth="1px"
        borderStyle="dotted"
        width="100%"
        borderColor="borderStrong"
      />
      <Box flexDirection="row" width="100%" mt="space6" alignItems="center">
        <Box>
          {loading ? (
            <Skeleton variant="text" width="50%" />
          ) : (
            <Text fontSize="87.5" color="text">
              {isBankTransferPaymentMethod
                ? t('total_invoice_due_days')
                : t('total_due_today')}
            </Text>
          )}
        </Box>
        <Box flex="1 1 auto" alignItems="end">
          {loading ? (
            <Skeleton variant="text" />
          ) : (
            <Text color="text" textAlign="end">
              {total}
            </Text>
          )}
        </Box>
      </Box>
      <Box width="100%" mt="space6">
        {loading ? (
          <Skeleton variant="rectangle" width="100%" height="58px" />
        ) : (
          <Text color="textWeaker" fontSize="87.5" whiteSpace="pre-line">
            <Trans
              t={t}
              i18nKey="auto_renewal_info"
              values={{
                licenses: t('licenses', { count: newPlan.licenses }),
                billingDate: newPlan.billingDate,
                total: newPlan.price,
              }}
              components={{ bold: <Text fontSize="87.5" color="textWeaker" /> }}
            />
          </Text>
        )}
      </Box>
      <Button
        state={purchaseLoading ? 'loading' : undefined}
        width="100%"
        disabled={Boolean(purchaseError) || loading || purchaseLoading}
        variant="primary"
        iconLeading={LockIcon}
        onClick={onSubmit}
        mt="space6"
      >
        {isBankTransferPaymentMethod
          ? t('generate_invoice')
          : t('confirm_and_pay_button')}
      </Button>
      <Box minHeight={16} bottom={0} width="100%" alignItems="center">
        <Text fontSize="87.5" color="textNegative">
          {purchaseError}
        </Text>
      </Box>
      <Text fontSize="75" color="textWeaker" mt="space2">
        <Trans
          t={t}
          i18nKey="terms"
          components={{
            'terms-link': (
              <Link
                color="text"
                href="/terms"
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      </Text>
    </>
  );
};
