import React, { useState } from 'react';
import type { PlanT, MetaT } from '@mentimeter/plans-content';
import { Box } from '@mentimeter/ragnar-ui';
import type { HandlePlanClickT } from '@mentimeter/checkout-modal';
import { getFormattedPrice } from '@mentimeter/billing';
import { useTranslation } from 'react-i18next';
import { ContactSalesModal } from '@mentimeter/workspace';
import { CheckoutModalsWrapper } from '../../CheckoutModalsWrapper';
import {
  PlanCell,
  ActionButton,
  Heading,
  AdditionalPriceInfo,
  DiscountedPrice,
  LicenseSelector,
  MainPrice,
  UniqueSellingPoints,
} from './components';
import { LICENSE_CAP } from './constants';

const PLAN_SECTIONS_COUNT = 6;

export function PlansPreviewGrid({ plans }: { plans: PlanT[] }) {
  const [showSalesModal, setShowSalesModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState<number>(1);
  const { t } = useTranslation(['plansContentPackage']);

  const numberOfPlans = plans.length;

  return (
    <CheckoutModalsWrapper>
      {({ handlePlanClick }) => (
        <Box
          display="grid"
          gridAutoFlow={['row', null, 'column']}
          gridTemplateColumns={[
            null,
            null,
            `repeat(${numberOfPlans}, minmax(0,320px))`,
          ]}
          gridTemplateRows={`repeat(${PLAN_SECTIONS_COUNT}, max-content)`}
          columnGap={3}
        >
          <ContactSalesModal
            showSalesModal={showSalesModal}
            setShowSalesModal={setShowSalesModal}
            trackingContext="Plans page"
          />
          {plans.map((plan) => {
            const sections = getPlanSections(plan, handlePlanClick);

            return <React.Fragment key={plan.id}>{sections}</React.Fragment>;
          })}
        </Box>
      )}
    </CheckoutModalsWrapper>
  );

  function getPlanSections(plan: PlanT, handlePlanClick: HandlePlanClickT) {
    const licenseValues = Array.from({ length: LICENSE_CAP }, (_, i) =>
      (i + 1).toString(),
    );
    const discountPrice = calculateDiscountedPlanPrice(plan);
    const shouldShowYearlyDiscount = (item: MetaT) => {
      return Boolean(
        !plan.discount &&
          item.yearlyDiscount &&
          item.yearlyDiscountText &&
          !plan.isPriceLoading,
      );
    };

    const highlight = Boolean(plan.highlight);

    return [
      <PlanCell key={plan.id + '-heading'} highlight={highlight} showTopBorder>
        <Heading heading={plan.heading} subheading={plan.subheading} />
      </PlanCell>,
      <PlanCell key={`${plan.id}-price`} highlight={highlight} pb="space1.5">
        {!!plan.discount && !plan.isPriceLoading && (
          <DiscountedPrice
            plan={plan}
            selectedValue={selectedValue}
            discountPrice={discountPrice}
            showLicenseSelector={plan.showLicenseSelector}
          />
        )}
        <MainPrice plan={plan} selectedValue={selectedValue} />
      </PlanCell>,
      <PlanCell key={`${plan.id}-price-info`} highlight={highlight} pt="space0">
        <AdditionalPriceInfo
          plan={plan}
          showYearlyDiscount={shouldShowYearlyDiscount}
        />
      </PlanCell>,
      plan.showLicenseSelector ? (
        <PlanCell key={plan.id + '-selector'} highlight={highlight}>
          <LicenseSelector
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            licenseValues={licenseValues}
            plan={plan}
            t={t}
          />
        </PlanCell>
      ) : (
        <PlanCell key={`${plan.id}-empty-cell`} p="space0" m="space0" />
      ),
      <PlanCell key={plan.id + '-button'} highlight={highlight}>
        <ActionButton
          plan={plan}
          selectedValue={selectedValue}
          setModalState={setShowSalesModal}
          onPlanClick={handlePlanClick}
          t={t}
        />
      </PlanCell>,
      <PlanCell key={plan.id + '-usps'} highlight={highlight} showBottomBorder>
        <UniqueSellingPoints uspHeading={plan.uspHeading} usps={plan.usp} />
      </PlanCell>,
    ];
  }
}

function calculateDiscountedPlanPrice(plan: PlanT): string {
  return (
    (plan?.currencySymbol &&
      plan?.priceFormat &&
      plan.discount?.baseAmount &&
      getFormattedPrice(
        plan.discount.baseAmount,
        plan.currencySymbol,
        plan.priceFormat,
      )) ||
    ''
  );
}
