import { Link, Text } from '@mentimeter/ragnar-ui';
import { Trans, useTranslation } from '@mentimeter/i18n';
import { ErrorMessageWrapper } from './components/ErrorMessageWrapper';
import { ErrorMessageTitle } from './components/ErrorMessageTitle';

export const UpgradeNotPossible = () => {
  const { t } = useTranslation('checkoutUiPackage');

  return (
    <ErrorMessageWrapper>
      <ErrorMessageTitle text={t('errors.upgrade_not_possible')} />
      <Text
        textAlign="center"
        mt="space4"
        style={{
          whiteSpace: 'pre',
        }}
      >
        <Trans
          t={t}
          i18nKey="errors.upgrade_not_possible_info"
          components={{
            mailToLink: <Link href="mailto:support@mentimeter.com" />,
          }}
        />{' '}
      </Text>
      <Text textAlign="center" mt="space4">
        <Trans
          t={t}
          i18nKey="errors.download_receipt_question"
          components={{
            download: <Link href="/app/billing" />,
          }}
        />
      </Text>
    </ErrorMessageWrapper>
  );
};
